import { useSendUserEvent } from "hooks/user-event/useSendUserEvent";

type Props = {
    pageId?: string;
    title: string;
    children?: any;
};

export const ConfluenceDocsTrigger: React.FC<Props> = (props: Props) => {
    const { pageId, title, children } = props;

    const sendUserEvent = useSendUserEvent();

    let trigger = children;

    if (pageId) {
        const handleOpenConfluenceWidgetDocs = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.wh.show({ pageId });

            sendUserEvent({
                type: "click:documentation",
                value: title,
            });
        };

        trigger = (
            <button type={"button"} onClick={handleOpenConfluenceWidgetDocs}>
                {children}
            </button>
        );
    }

    return trigger;
};
