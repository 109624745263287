export const getURLSearchParam = <T extends string>(param: T) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
};

export const setURLSearchParam = <T extends string>(param: T, value: string | null) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (value === null || value === undefined || value === "") {
        searchParams.delete(param);
    } else {
        searchParams.set(param, value);
    }

    const url = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(
        null,
        /**
         * https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState
         * This parameter exists for historical reasons, and cannot be omitted; passing the empty string is traditional, and safe against future changes to the method.
         */
        "",
        url
    );
};
